var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Nome')}},[_c('b-form-input',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"placeholder":"","autocomplete":"off"},model:{value:(_vm.nome),callback:function ($$v) {_vm.nome=$$v},expression:"nome"}})],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Apelido')}},[_c('b-form-input',{attrs:{"placeholder":"","autocomplete":"off"},model:{value:(_vm.apelido),callback:function ($$v) {_vm.apelido=$$v},expression:"apelido"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"rules":{numberVueTelInput: _vm.numberPhoneValidate }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Telefone')}},[_c('vue-tel-input',{ref:"phone",staticClass:"form-control",class:errors.length > 0 ? 'is-invalid':null,attrs:{"inputmode":"tel","mode":_vm.configPhone.mode,"valid-characters-only":_vm.configPhone.validCharactersOnly,"auto-format":_vm.configPhone.autoFormat,"default-country":_vm.configPhone.defaultCountry,"auto-default-country":_vm.configPhone.autoDefaultCountry,"input-options":_vm.configPhone.inputOptions,"dropdown-options":_vm.configPhone.dropdownOptions},on:{"input":_vm.checkValidateNumberPhone,"open":_vm.onDropdownOpenTelInput,"hook:mounted":_vm.loadDataFormPhone},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }$event.preventDefault();return $event.target.blur()},"!blur":function($event){$event.preventDefault();return _vm.inputValidateNumberPhone(_vm.$refs.phone)}},model:{value:(_vm.telefone),callback:function ($$v) {_vm.telefone=$$v},expression:"telefone"}},[_c('template',{slot:"arrow-icon"},[_vm._v(" "+_vm._s('\xa0')+" ")]),_c('template',{slot:"icon-right"},[_vm._v(" "+_vm._s('\xa0')+" ")])],2)],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Email')}},[_c('b-form-input',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"placeholder":"","autocomplete":"off","inputmode":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"label":_vm.$t('Género')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.load_data_geral.generos,"append-to-body":"","calculate-position":_vm.withPopper,"label":"txt","item-text":"txt","item-value":"id"},on:{"input":_vm.getAllGrausParentesco},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var txt = ref.txt;
return [_vm._v(" "+_vm._s(txt)+" ")]}}],null,true),model:{value:(_vm.genero),callback:function ($$v) {_vm.genero=$$v},expression:"genero"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Sem resultados'))+" ")])])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"label":_vm.$t('Grau parentesco')}},[_c('v-select',{ref:"grauParentesco",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.grausParentesco,"append-to-body":"","calculate-position":_vm.withPopper,"loading":_vm.loaderGrau,"label":"sw024s02","item-text":"sw024s02","item-value":"sw024s01","placeholder":((_vm.genero === null) ? _vm.$t('Escolha um género') : '')},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var sw024s02 = ref.sw024s02;
return [_vm._v(" "+_vm._s(sw024s02)+" ")]}}],null,true),model:{value:(_vm.grau),callback:function ($$v) {_vm.grau=$$v},expression:"grau"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Sem resultados'))+" ")])])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Data de aniversário')}},[_c('date-picker',{attrs:{"value-type":"format","input-class":"form-control","type":"date","editable":false,"clearable":true,"lang":_vm.langCalendar,"append-to-body":true,"prefix-class":"sw","disabled-date":_vm.disabledMaxDateBirtday},scopedSlots:_vm._u([{key:"icon-clear",fn:function(){return [_c('v-select-deselect')]},proxy:true}]),model:{value:(_vm.data_aniversario),callback:function ($$v) {_vm.data_aniversario=$$v},expression:"data_aniversario"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"rules":((_vm.$store.getters['auth/countryApp'] == 'pt') ? 'nif': '')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":((_vm.$store.getters['auth/countryApp'] == 'es') ? _vm.$t('DNI') : _vm.$t('NIF'))}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.tokenMaskNIF),expression:"tokenMaskNIF"}],class:errors.length > 0 ? 'is-invalid':null,attrs:{"placeholder":"","autocomplete":"off","type":((_vm.$store.getters['auth/countryApp'] == 'pt') ? 'number': 'text')},model:{value:(_vm.nif),callback:function ($$v) {_vm.nif=$$v},expression:"nif"}})],1)]}}])})],1)],1),(_vm.$store.getters['auth/countryApp']==='pt')?_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('BI / CC')}},[_c('b-form-input',{attrs:{"placeholder":"","autocomplete":"off"},model:{value:(_vm.bi_cc),callback:function ($$v) {_vm.bi_cc=$$v},expression:"bi_cc"}})],1)],1)],1):_vm._e(),(_vm.$store.getters['auth/countryApp']==='pt')?_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Validade do BI / CC')}},[_c('date-picker',{attrs:{"value-type":"format","input-class":"form-control","type":"date","editable":false,"clearable":true,"lang":_vm.langCalendar,"append-to-body":true,"prefix-class":"sw"},scopedSlots:_vm._u([{key:"icon-clear",fn:function(){return [_c('v-select-deselect')]},proxy:true}],null,false,180141728),model:{value:(_vm.data_validade_bi_cc),callback:function ($$v) {_vm.data_validade_bi_cc=$$v},expression:"data_validade_bi_cc"}})],1)],1)],1):_vm._e(),(_vm.$store.getters['auth/countryApp']==='es')?_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('NIE')}},[_c('b-form-input',{attrs:{"placeholder":"","autocomplete":"off"},model:{value:(_vm.nie),callback:function ($$v) {_vm.nie=$$v},expression:"nie"}})],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }